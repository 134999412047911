// eslint-disable-next-line import/no-cycle
import api from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    licensees: [],
  },

  getters: {
    getLicensee(state) {
      return state.licensees
    },
  },
  mutations: {
    GET_LICENSEE(state, data) {
      state.licensees = data
    },
  },
  actions: {
    allLicensee({ commit, actions }) {
      api.get('/api/licensees')
        .then(response => {
          commit('GET_LICENSEE', response.data.data)
          return Promise.resolve(response)
        }).catch(error => Promise.reject(error.response.data.message))
    },
  },

}
