import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueScrollTo from 'vue-scrollto'
import VueSlickCarousel from 'vue-slick-carousel'
import Vidle from 'v-idle'
import VueDatePicker from '@mathieustan/vue-datepicker'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import router from './router'
import store from './store'
import App from './App.vue'

import * as truncate from './libs/truncate'
// Global Components
import './global-components'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import '@/libs/sweet-alerts'
import 'swiper/css/swiper.css'
import 'toastify-js/src/toastify.css'
// Vue.filter('truncate', truncate);
Object.keys(truncate).forEach(key => {
  Vue.filter(key, truncate[key])
})

Vue.use(Vidle)
Vue.use(Vuelidate)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueHtmlToPaper)
Vue.use(VueDatePicker)
// Composition API
Vue.use(VueCompositionAPI)
// import ck editor
Vue.use(CKEditor)
Vue.component('vue-slick-carousel', VueSlickCarousel)
// import core styles
require('@core/scss/core.scss')
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
})
// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
