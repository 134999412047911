// eslint-disable-next-line import/prefer-default-export
import api from '../../libs/axios'

export const auth = {
  namespaced: true,
  state: {
    auth: {
      user: {},
      token: null,
      refresh_token: null,
      roles: [],
      permissions: [],
      stepData: null,
    },
    stepOneCompleted: false,
    stepTwoCompleted: false,
    stepThreeCompleted: false,
    getStartedCompleted: false,
    faqs: [],
    reviews: [],
    footerContent: {},
    siteContents: [],
    updateRequest: false,
    applicationId: null,
    aboutContent: {},
    contactContent: {},
    carouselContent: {},
    loanApplication: {},
    loanApplications: [],
    loanApplicationsCount: 0,
    mortgageId: null,
    mortgageStatus: null,
    socketId: null,
    loanOriginator: null,
  },
  mutations: {
    setLoanOriginator(state,data){
      state.loanOriginator = data
    },
    setMortgageStatus(state,status){
      state.mortgageStatus = status
    },
    setMortgageId(state,id){
      state.mortgageId = id
    },
    setSocketId(state,id){
      state.socketId = id
    },
    updateMortgageMessagesCount(state,mortgageId){
      state.loanApplications.forEach(mort => {
        if(mort.id===mortgageId){}
      });
    },
    setLoanApplicationsCount(state, count) {
      state.loanApplicationsCount = count
    },
    setLoanApplications(state, applications) {
      state.loanApplications = applications
      state.loanApplicationsCount = applications.length
    },
    setLoanApplication(state, application) {
      state.loanApplication = application
    },
    setCarouselContent(state, carousel) {
      state.carouselContent = carousel
    },
    setContactContent(state, contacts) {
      state.contactContent = contacts
    },
    setAboutContent(state, abouts) {
      state.aboutContent = abouts
    },
    setQuestionsRequestType(state, status) {
      state.updateRequest = status.updateRequest
      state.applicationId = status.applicationId
    },
    setSiteContents(state, contents) {
      state.siteContents = contents
    },
    setFooterContent(state, content) {
      state.footerContent = content
    },
    setFaqs(state, data) {
      state.faqs = data.faqs
      state.reviews = data.reviews
      state.contactContent = data.contact
      state.carouselContent = data.carousel
      state.aboutContent = data.about
    },
    setStep(state, step) {
      state.auth.stepData = step
    },
    setUserVerified(state) {
      state.auth.user.verified = true
    },
    updateToken(state, token) {
      state.auth.token = token
    },
    setUserData: (state, userData) => {
      state.auth = userData
    },
    clearAuthState(state) {
      state.auth = {}
      localStorage.removeItem('hwa_app')
    },
    updateUser(state, user) {
      state.auth.user = { ...state.auth.user, ...user }
      if(user.user_type==='licensee' && !!user.role){
        state.auth.roles = [user.role]
      }
    },
    updateToken(state, token) {
      state.auth.token = token.token
      state.auth.refresh_token = token.refresh_token
    },
  },
  getters: {
    loanOriginator(state){
      return state.loanOriginator
    },
    userType(state){
      return state.auth.user?.user_type
    },
    socketId(state){
      return state.socketId
    },
    mortgageId(state){
      return state.mortgageId
    },
    stepData(state) {
      return state.auth.stepData
    },
    isStaff(state) {
      return state.auth.roles[0] === 'Staff' || state.auth.roles[0] === 'Manager'
    },
    isAdmin(state) {
      return state.auth.roles[0] === 'Admin'
    },
    roles(state) {
      return state.auth.roles
    },
    role(state) {
      return state.auth.roles[0]
    },
    ability(state) {
      const utype = state.auth.user?.user_type
      if (
        utype==="admin"|| utype==="borrower"
        || utype==="licensee") {
        return null
      }
      return state.auth.permissions?.map(a => ({
        action: a,
        subject: 'hwa',
      }))
    },
    accessToken(state) {
      return state.auth.token
    },
    refreshToken(state) {
      return state.auth.refresh_token
    },
    isLoggedIn(state) {
      return !!state.auth.token
    },
    isVerified(state) {
      return !!state.auth.user?.verified
    },
    appBarData(state) {
      const role = state.auth?.roles[0]
      const path = state.auth.user.avatar === null ? null : `${process.env.VUE_APP_STORAGE_PATH}/${state.auth.user.avatar}`
      return {
        name: state.auth.user.first_name,
        role,
        avatar: path,
        user_type: state.auth.user.user_type,
        id: state.auth.user.id,
        first_name: state.auth.user.first_name,
        last_name: state.auth.user.last_name,
        photo: state.auth.user.avatar,
      }
    },
    userId(state){
      return state.auth?.user?.id;
    },
    user(state) {
      const path = state.auth.user.avatar === null ? '' : `${process.env.VUE_APP_STORAGE_PATH}/${state.auth.user.avatar}`
      return {
        id: state.auth.user.id,
        first_name: state.auth.user.first_name,
        last_name: state.auth.user.last_name,
        email: state.auth.user.email,
        avatar: path,
        phone: state.auth.user.phone,
        bio: state.auth.user.bio,
        licensee_type: state.auth.user.licensee_type,
        brokerage_firm: state.auth.user.brokerage_firm,
        broker_name: state.auth.user.broker_name,
        broker_phone: state.auth.user.broker_phone,
        
      }
    },
    authUser(state) {
      return state.auth.user
    },
    vendor(state) {
      return state.auth.user.vendor
    },
    faqs(state) {
      return state.faqs
    },
    reviews(state) {
      return state.reviews
    },
    footerContent(state) {
      return state.footerContent
    },
    aboutContent(state) {
      return state.aboutContent
    },
    contactContent(state) {
      return state.contactContent
    },
    carouselContent(state) {
      return state.carouselContent
    },
    siteContent: state => type => state.siteContents.find(c => c.type === type),
    loanApplication(state) {
      return state.loanApplication
    },
    mortgageStatus(state){
      return state.mortgageStatus
    },
    loanApplications(state) {
      return state.loanApplications
    },
    loanApplicationsCount(state) {
      return state.loanApplicationsCount
    },
  },
  actions: {
    updateApplication({ state }, submit = false) {
      return api.put(`/api/applications/update?submit=${submit}`, { application: state.loanApplication })
    },
  },
}
