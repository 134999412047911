import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import site from './mortgageenvy'
import chat from './mortgageenvy/admin'
import licensee from './mortgageenvy/licensee'
import blog from './mortgageenvy/blog'
import application from './mortgageenvy/application'
import notes from './mortgageenvy/note'
import createPersistedState from 'vuex-persistedstate'
// Modules
import { auth } from './auth'

const dataState = createPersistedState({
  key: 'hwa_app',
  paths: ['auth'],
})
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    site,
    chat,
    licensee,
    blog,
    application,
    notes,
  },
  strict: process.env.DEV,
  plugins: [dataState],
})
