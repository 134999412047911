import store from '@/store'
// eslint-disable-next-line import/prefer-default-export
export const websiteRoutes = [
  {
    path: '/',
    component: () => import('@/views/mortgageenvy/Site.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
    },

  },
  {
    path: '/site',
    component: () => import('@/views/mortgageenvy/site-pages/Site.vue'),
    meta: {
      layout: 'full',
    },
    children: [
      {
        path: 'glossary',
        name: 'glossary',
        component: () => import('@/views/mortgageenvy/Glossary.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'media',
        name: 'media',
        component: () => import('@/views/mortgageenvy/Media.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/mortgageenvy/Privacy.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'terms-of-use',
        name: 'terms-of-use',
        component: () => import('@/views/mortgageenvy/Terms.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'disclosures-licensing',
        name: 'disclosures-licensing',
        component: () => import('@/views/mortgageenvy/Disclosure.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'affiliated-business',
        name: 'affiliated-business',
        component: () => import('@/views/mortgageenvy/AffiliatedBusiness.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'browser-disclaimer',
        name: 'browser-disclaimer',
        component: () => import('@/views/mortgageenvy/Disclaimer.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'partner-with-us',
        name: 'partner-with-us',
        component: () => import('@/views/mortgageenvy/Partner.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'learn',
        name: 'learn',
        component: () => import('@/views/mortgageenvy/Learn.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
      {
        path: 'careers',
        name: 'careers',
        component: () => import('@/views/mortgageenvy/Careers.vue'),
        meta: {
          public: true,
          layout: 'full',
        },
      },
    ],
  },
  {
    path: '/blog',
    component: () => import('@/views/mortgageenvy/Blog.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: '/blog-category/:title',
    component: () => import('@/views/mortgageenvy/BlogCategory.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: '/blog/:id',
    component: () => import('@/views/mortgageenvy/BlogDetail.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
    },
  },
  {
    path: '/about',
    component: () => import('@/views/mortgageenvy/AboutUs.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/site/faqs',
    component: () => import('@/views/mortgageenvy/FaqView.vue'),
    meta: {
      layout: 'full',
      public: true,
      requiresAuth: false,
    },
  },
  {
    path: '/mortgage/:id',
    component: () => import('@/views/mortgageenvy/site-content/MortgageDetails.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/appointment',
    component: () => import('@/views/mortgageenvy/Appointment.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/mortgage-type',
    component: () => import('@/views/mortgageenvy/Service.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/contact',
    component: () => import('@/views/mortgageenvy/ContactUs.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },

]
