export default {
  namespaced: true,
  state: {
    enable_application_view: false,
    enable_application: false,
  },

  getters: {
    enableApplicationView(state) {
      return state.enable_application_view
    },
    enableApplication(state) {
      return state.enable_application
    },
  },
  mutations: {
    GET_ENABLE_APPLICATION_VIEW(state, data) {
      state.enable_application_view = data
    },
    GET_ENABLE_APPLICATION(state, data) {
      state.enable_application = data
    },
  },
  actions: {
    enableApplicationView({ commit }, data) {
      commit('GET_ENABLE_APPLICATION_VIEW', data)
    },
    enableApplication({ commit }, data) {
      commit('GET_ENABLE_APPLICATION', data)
    },
  },
}
