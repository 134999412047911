import store from '@/store'

// eslint-disable-next-line import/prefer-default-export
export const questionnaireRoutes = [
  {
    path: '/new-user',
    component: () => import('@/views/mortgageenvy/layout/GetStartedLayout.vue'),
    children: [
      {
        path: '/get-started',
        name: 'get-started',
        component: () => import('@/views/mortgageenvy/GetStarted.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'purchase/step-1',
        name: 'step-1',
        component: () => import('@/views/mortgageenvy/questionnaires/purchase/StartPurchaseOne.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.getStartedCompleted) {
            return next()
          }
          next('/')
        },
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'purchase/step-2',
        name: 'step-2',
        component: () => import('@/views/mortgageenvy/questionnaires/purchase/StartPurchaseTwo.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepOneCompleted) {
            return next()
          }
          next('/')
        },
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'purchase/step-3',
        name: 'step-3',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepTwoCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/questionnaires/purchase/StartPurchaseThree.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'purchase/step-4',
        name: 'step-4',
        component: () => import('@/views/mortgageenvy/questionnaires/purchase/StartPurchaseFour.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepThreeCompleted) {
            return next()
          }
          next('/')
        },
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: ':type/complete',
        name: 'complete',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepThreeCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/CompleteAccount.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'refinance/step-1',
        name: 'r-step-1',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.getStartedCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/questionnaires/refinance/StartRefinanceOne.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'refinance/step-2',
        name: 'r-step-2',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepOneCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/questionnaires/refinance/StartRefinanceTwo.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'refinance/step-3',
        name: 'r-step-3',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepTwoCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/questionnaires/refinance/StartRefinanceThree.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
      {
        path: 'refinance/step-4',
        name: 'r-step-4',
        beforeEnter: (to, from, next) => {
          if (store.state.auth.stepThreeCompleted) {
            return next()
          }
          next('/')
        },
        component: () => import('@/views/mortgageenvy/questionnaires/refinance/StartRefinanceFour.vue'),
        meta: {
          layout: 'full',
          public: true,
        },
      },
    ],
  },
]
