import Echo from 'laravel-echo'
import store from '../store'

const host = process.env.VUE_APP_WEBSOCKET_URL
const tls = process.env.VUE_APP_WEBSOCKET_TLS
const token = store.getters['auth/accessToken']

const options = {
  broadcaster: 'socket.io',
  host,
  forceTLS: tls,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export const echo = new Echo(options)
