import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { authRoutes } from './auth.routes'
import { websiteRoutes } from './mortgageenvy/website.routes'
import { questionnaireRoutes } from './mortgageenvy/questionnaire.routes'
import { adminRoutes } from './mortgageenvy/admin.routes'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...authRoutes,
    ...adminRoutes,
    ...questionnaireRoutes,
    ...websiteRoutes,
    {
      path: '/app-template',
      name: 'app-template',
      component: () => import('@/views/app/Welcome.vue'),
      meta: {
        public: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    }, {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/error/Unauthorized.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/isLoggedIn']
  if(!canNavigate(to) && !to.meta?.public){
    if (!loggedIn) return next({ name: 'login' })
    if (to.meta.profile) {
      return next()
    }
    return next({ name: 'unauthorized' })
  }
  const emailVerified = store.getters['auth/isVerified']
  if(to.meta?.auth){
    if (loggedIn) {
      next({ path: '/' })
    } else {
      next()
    }
  }
  if (to.meta.public) {
    return next()
  }
  if (to.name === 'verify') {
    return next()
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else if (to.name === 'unverified') {
      next()
    } else if (emailVerified) {
      next()
    } else {
      next({ name: 'unverified' })
    }
  }
})
export default router
