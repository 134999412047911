// eslint-disable-next-line import/prefer-default-export
export const adminRoutes = [
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/mortgageenvy/admin/DataCenter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/manage-managers',
    name: 'manage-managers',
    component: () => import('@/views/mortgageenvy/admin/AssignRoles.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/manage-clients',
    name: 'manage-clients',
    component: () => import('@/views/app/staff/ManageClients.vue'),
    meta: {
      requiresAuth: true,
      resource: "hwa",
      action: "view-borrowers"
    },
  },
  {
    path: '/manage-user-access',
    name: 'manage-user-access',
    component: () => import('@/views/mortgageenvy/admin/ManageUserAccess.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/mortgageenvy/admin/Faq.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/data-center',
    name: 'data-center',
    component: () => import('@/views/mortgageenvy/admin/DataCenter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/approve-reviews',
    name: 'approve-reviews',
    component: () => import('@/views/mortgageenvy/admin/ApproveReviews.vue'),
    meta: {
      requiresAuth: true,
      resource: 'hwa',
      action: 'view-reviews',
    },
  },
  {
    path: '/carousel-content',
    name: 'carousel-content',
    component: () => import('@/views/mortgageenvy/admin/CarouselContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/blog-posts',
    name: 'blog-posts',
    component: () => import('@/views/mortgageenvy/admin/BlogPosts.vue'),
    meta: {
      requiresAuth: true,
      resource: 'hwa',
      action: 'view-blog-posts',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/mortgageenvy/admin/Services.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/careers-content',
    name: 'careers-content',
    component: () => import('@/views/mortgageenvy/admin/CareersContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/partner-with-content',
    name: 'partner-content',
    component: () => import('@/views/mortgageenvy/admin/PartnerWithUsContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact-content',
    name: 'contact-content',
    component: () => import('@/views/mortgageenvy/admin/ContactContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/footer-content',
    name: 'footer-content',
    component: () => import('@/views/mortgageenvy/admin/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about-content',
    name: 'about-content',
    component: () => import('@/views/mortgageenvy/admin/AboutContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/privacy-content',
    name: 'privacy-content',
    component: () => import('@/views/mortgageenvy/admin/PrivacyContent.vue'),
    meta: {
      requiresAuth: true,
      resource: 'hwa',
      action: 'update-privacy-policy',
    },
  },
  {
    path: '/terms-content',
    name: 'terms-content',
    component: () => import('@/views/mortgageenvy/admin/TermsContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/disclosure-content',
    name: 'disclosure-content',
    component: () => import('@/views/mortgageenvy/admin/DisclosureContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/glossary-content',
    name: 'glossary-content',
    component: () => import('@/views/mortgageenvy/admin/GlossaryContent.vue'),
    meta: {
      requiresAuth: true,
      resource: 'hwa',
      action: 'update-glossary-content',
    },
  },
  {
    path: '/affiliated-content',
    name: 'affiliated-content',
    component: () => import('@/views/mortgageenvy/admin/AffiliatedContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/disclaimer-content',
    name: 'disclaimer-content',
    component: () => import('@/views/mortgageenvy/admin/DisclaimerContent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-home-dashboard',
    component: () => import('@/views/mortgageenvy/admin/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/assign-roles',
    name: 'assign-roles',
    component: () => import('@/views/mortgageenvy/admin/AssignRoles.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/backup-restore',
    name: 'backup-restore',
    component: () => import('@/views/mortgageenvy/admin/BackupRestore.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/app/auth/account/Profile.vue'),
    meta: {
      requiresAuth: true,
      profile: true,
    },
  },
  {
    path: '/clients',
    component: () => import('@/layouts/HwaLayout.vue'),
    children: [
      {
        path: 'agents',
        name: 'agents',
        component: () => import('@/views/app/client/Agents.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'client-dashboard',
        name: 'client-dashboard',
        component: () => import('@/views/app/client/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'reviews',
        name: 'app-reviews',
        component: () => import('@/views/app/client/Reviews.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'licensees',
        name: 'licensees',
        component: () => import('@/views/app/client/Licensee.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'customer-applications',
        name: 'customer-applications',
        component: () => import('@/views/app/client/applications/Index.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'customer-applications/:id',
        name: 'single-customer-applications',
        component: () => import('@/views/app/client/Applications.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'questionnaires',
        name: 'questionnaires',
        component: () => import('@/views/app/client/Questionnaires.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'chats',
        name: 'chats',
        component: () => import('@/views/mortgageenvy/chat/Chat.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'messages',
        name: 'message-center',
        component: () => import('@/views/app/staff/Messages.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/agent',
    component: () => import('@/layouts/HwaLayout.vue'),
    children: [
      {
        path: 'agent-dashboard',
        name: 'agent-dashboard',
        component: () => import('@/views/app/agent/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'clients',
        name: 'clients',
        component: () => import('@/views/app/agent/Client.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/staff',
    component: () => import('@/layouts/HwaLayout.vue'),
    children: [
      // {
      //   path: 'staff-dashboard',
      //   name: 'staff-dashboard',
      //   component: () => import('@/views/app/staff/Dashboard.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     resource: 'hwa',
      //     action: 'view-dashboard',
      //   },
      // },
      {
        path: 'associate-licensees',
        name: 'associate-licensees',
        component: () => import('@/views/app/staff/Licensee.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manage-staff',
        name: 'manage-staff',
        component: () => import('@/views/app/staff/ManageStaff.vue'),
        meta: {
          requiresAuth: true,
          resource: 'hwa',
          action: 'view-staff-information',
        },
      },
      {
        path: 'manage-roles',
        name: 'manage-roles',
        component: () => import('@/views/app/staff/ManageRoles.vue'),
        meta: {
          requiresAuth: true,
          resource: 'hwa',
          action: 'view-roles',
        },
      },
      {
        path: 'client-applications/:id',
        name: 'client-applications',
        component: () => import('@/views/app/client/Applications.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manage-applications',
        name: 'manage-applications',
        component: () => import('@/views/app/staff/ManageApplications.vue'),
        meta: {
          requiresAuth: true,
          resource: 'hwa',
          action: 'view-dashboard',
        },
      },
      {
        path: 'manage-agents',
        name: 'manage-agents',
        component: () => import('@/views/app/staff/ManageAgents.vue'),
        meta: {
          requiresAuth: true,
          resource: 'hwa',
          action: 'view-real-estate-licensees',
        },
      },
      {
        path: 'manage-companies',
        name: 'manage-companies',
        component: () => import('@/views/app/staff/ManageCompany.vue'),
        meta: {
          requiresAuth: true,
          resource: 'hwa',
          action: 'view-blog-posts',
        },
      },
    ],
  },
]
