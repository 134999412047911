import api from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    chats: [],
    contacts: [],
    single_chats: [],
    backups: [],
    restore_backups: [],
    currentPage: 1,
    rows: 1,
    mortgages:[],
    clients:[],
  },
  getters: {
    clients(state){
      return state.clients
    },
    mortgages(state){
      return state.mortgages
    },
    getChats(state) {
      return state.chats
    },
    getContacts(state) {
      return state.contacts
    },
    getBackups(state) {
      return state.backups
    },
    getRestoreBackups(state) {
      return state.restore_backups
    },
  },
  mutations: {
    clearMortgagesUnreadCount(state,id){
      state.mortgages = state.mortgages.map((m)=>{
        if(m.id===id){
          m.unread_messages = 0;
          return m;
        }
        return m;
      })
      state.clients = state.clients.map((c)=>{
        if(c.mortgage_id===id){
          c.unread_messages = 0;
          return c;
        }
        return c;
      })
    },
    setClients(state,data){
      state.clients = data
    },
    setMortgages(state,data){
      state.mortgages = data
    },
    updateSingleChat(state,chat){
      state.chats = state.chats.map((c)=>{
        if(c.id===chat.id){
          return {...c,...chat}
        }
        return c
      })
    },
    updateChats(state,ch){
      const chat = ch.chat
      state.chats = [...state.chats,chat["message"]]
      const c = state.contacts.find((c)=>c.id===chat["contact"].id)
      if(!c){
        state.contacts  = [...state.contacts,chat["contact"]]
      }
    },
    GET_CHATS(state, data) {
      state.chats = Object.values(data)
    },
    GET_CONTACTS(state, data) {
      state.contacts = Object.values(data)
    },
    GET_SINGLE_CHATS(state, data) {
      state.single_chats = data
    },
    GET_BACKUPS(state, data) {
      state.backups = data.data
      state.rows = data.data.total
      state.currentPage = data.data.current_page
    },
    GET_RESTORE_BACKUPS(state, data) {
      state.restore_backups = data
    },
  },
  actions: {

    fetchChatsAndContacts({ commit, actions }, query) {
      api.get(`/api/chats/${query.mortgage_id}`, { query }).then(response => {
        commit('GET_CHATS', response.data.messages.reverse())
        commit('GET_CONTACTS', response.data.contacts)
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error))
    },

    getSingleChat({ commit, actions }, data) {
      api.get(`/api/messages/${data.mortgage_id}`).then(response => {
        commit('GET_SINGLE_CHATS', response.data.reverse())
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error))
    },
    clearSingleChat({ commit }) {
      return commit('GET_SINGLE_CHATS', [])
    },

    sendMessage({ }, data) {
      return api.post('/api/messages', data)
    },

    fetchBackups({ commit, actions }, query) {
      api.get(`/api/admin/database-backups?date=${query.date}`, query).then(response => {
        commit('GET_BACKUPS', response)
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error.response.data.message))
    },
    fetchRestoreBackups({ commit, actions }) {
      api.get('/api/admin/get-restore-backups').then(response => {
        commit('GET_RESTORE_BACKUPS', response.data)
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error.response.data.message))
    },
    makeBackup({ }, data) {
      api.post('/api/admin/make-backups', data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error.response.data.message))
    },
    restoreDatabase({ }, data) {
      api.post('/api/admin/restore-database', data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error.response.data.message))
    },
    deleteDatabase({ }, data) {
      api.post('/api/admin/delete-backup', data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error.response.data.message))
    },
  },
}
