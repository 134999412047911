// eslint-disable-next-line import/prefer-default-export
export const authRoutes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/app/auth/Login.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import('@/views/app/auth/Login.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('@/views/app/auth/register/Register.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/staff-register',
    name: 'team-register',
    component: () => import('@/views/app/auth/register/StaffRegister.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/agent-register',
    name: 'agent-register',
    component: () => import('@/views/app/auth/register/AgentRegister.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/app/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/reset',
    name: 'reset',
    component: () => import('@/views/app/auth/ResetPassword.vue'),
    beforeEnter: ((to, from, next) => {
      if (!to.query.token || !to.query.email) {
        return next({ name: 'login' })
      }
      return next()
    }),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/verify',
    name: 'verify',
    beforeEnter: ((to, from, next) => {
      if (!to.query.id || !to.query.hash) {
        return next({ name: 'login' })
      }
      return next()
    }),
    component: () => import('@/views/app/auth/EmailVerification.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/auth/unverified',
    name: 'unverified',
    component: () => import('@/views/app/auth/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      requiresAuth: true,
      public: true,
    },
  },
  {
    path: '/staff',
    name: 'staff',
    redirect: { name: 'staff-login' },
  },
  {
    path: '/staff/register',
    name: 'staff-register',
    component: () => import('@/views/app/auth/staff/Register.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
  {
    path: '/staff/login',
    name: 'staff-login',
    component: () => import('@/views/app/auth/staff/Login.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      public: true,
      auth:true,
    },
  },
]
