import Vue from 'vue'

// axios
import axios from 'axios'
import store from '../store'
import router from '../router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json'},
})
axiosIns.interceptors.request.use(config => {
  const token = store.getters['auth/accessToken']
  const sId = store.getters['auth/socketId']
  // eslint-disable-next-line no-param-reassign
  // config.headers.Authorization = `Bearer ${token}`
  // eslint-disable-next-line no-param-reassign
  const key = 'Authorization'
  // eslint-disable-next-line no-param-reassign
  config.headers[key] = `Bearer ${token}`
  config.headers['X-Socket-Id'] = sId
  return config
})
axiosIns.interceptors.response.use(response => response, error => {
  const status = error.response.status
  const config = error.config
  if (status === 401 || status === 403) {
    if(status===401 && router.currentRoute.meta?.requiresAuth){
      const delayRetryRequest = new Promise((resolve,reject) => {
        axiosIns.post("api/auth/refresh",{refresh_token:store.getters['auth/refreshToken']}).then((res)=>{
          store.commit('auth/updateToken',res.data)
          resolve(config);
        }).catch((err)=>{
          reject(err)
        })
      });
      return delayRetryRequest.then((c) => axiosIns(c)).catch((err)=>{
        axiosIns.post("api/auth/logout").then((res)=>{
          store.commit('auth/clearAuthState')
          if (router.currentRoute.name !== 'login' && router.currentRoute.name !== 'staff-login') {
            router.push('/auth/login')
          }
        }).catch((err)=>{
          return Promise.reject(error.response)
        });
      });
    }
    return Promise.reject(error.response)
  }
  if (error.response && error.response.data) {
    return Promise.reject(error.response)
  }
  return Promise.reject(error.response)
})
Vue.prototype.$http = axiosIns

export default axiosIns
