// eslint-disable-next-line import/no-cycle
import api from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    posts: [],
    post: {},
    latest_post: [],
    filter_post: [],
    services: [],
    service: {},
    categoryId: null,
    totalRows: 0,
    perPage: 15,
    currentPage: 1,
  },
  getters: {
    totalRows(state) {
      return state.totalRows
    },
    perPage(state) {
      return state.perPage
    },
    currentPage(state) {
      return state.currentPage
    },
    categoryId(state) {
      return state.categoryId?.toLowerCase()
    },
    getPosts(state) {
      return state.posts
    },
    showPost(state) {
      return state.post
    },
    getLatestPost(state) {
      return state.latest_post
    },
    getFilterPost(state) {
      return state.latest_post
    },
  },
  mutations: {
    setPageMetaData(state, data) {
      state.perPage = data.per_page
      state.totalRows = data.total
      state.currentPage = data.current_page
    },
    setCategoryId(state, id) {
      state.categoryId = id
    },
    setPosts(state, posts) {
      state.posts = posts
    },
    GET_POSTS(state, data) {
      state.posts = data
    },
    SHOW_POST(state, data) {
      state.post = data
    },
    GET_LATEST_POST(state, data) {
      const item = data.slice(0, 3)
      state.latest_post = item
    },
    FILTER_POST(state, data) {
      state.filter_post = data
    },
  },
  actions: {
    allPosts({ commit, actions }, query = '') {
      api.get(`/api/blog_posts?${query}`)
        .then(response => {
          commit('GET_POSTS', response.data.data)
          commit('GET_LATEST_POST', response.data.data)
          commit('setPageMetaData', { current_page: response.data.current_page, per_page: response.data.per_page, total: response.data.total })
          return Promise.resolve(response)
        }).catch(error => Promise.reject(error.response.data.message))
    },
    showSinglePost({ commit, action }, slug) {
      api.get(`/api/blog_posts/${slug}`).then(res => {
        commit('SHOW_POST', res.data)
        return Promise.resolve(res)
      }).catch(error => Promise.reject(error.response.data.message))
    },
    getFilterPost({ commit, action }, id) {
      api.get('/api/blog_posts')
        .then(response => {
          commit('GET_POSTS', response.data.data)
          commit('GET_LATEST_POST', response.data.data)
          commit('setPageMetaData', { current_page: response.data.current_page, per_page: response.data.per_page, total: response.data.total })
          const blog = response.data.data.filter(x => x.slug !== id)
          const posts = blog.slice(0, 5)
          commit('FILTER_POST', posts)
          return Promise.resolve(response)
        }).catch(error => Promise.reject(error.response.data.message))
    },
  },
}
