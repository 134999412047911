// eslint-disable-next-line import/no-cycle
import Toastify from 'toastify-js'
// eslint-disable-next-line import/no-cycle
import api from '../../libs/axios'
// eslint-disable-next-line import/no-cycle
function toastNotification(message) {
  return Toastify({
    text: `${message}`,
    gravity: 'top', // `top` or `bottom`
    position: 'center', // `left`, `center` or `right`
    duration: 3000,
    style: {
      background: 'linear-gradient(to right, #00b09b, #96c93d)',
    },
  }).showToast()
}

export default {
  namespaced: true,
  state: {
    notes: [],
  },

  getters: {
    getNotes(state) {
      return state.notes
    },
  },
  mutations: {
    GET_NOTES(state, data) {
      state.notes = data.reverse()
    },
  },
  actions: {
    allNotes({ commit }, id) {
      api.get(`/api/notes/${id}`)
        .then(response => {
          commit('GET_NOTES', response.data)
          return Promise.resolve(response)
        }).catch(error => Promise.reject(error.response.data.message))
    },
    storeNote({}, data) {
      return api.post('/api/notes', data).then(response => {
        toastNotification('note save successfully')
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error.response.data.message))
    },
    updateNote({ dispatch }, data) {
      return api.put(`/api/notes/${data.id}`, data).then(response => {
        // dispatch('allNotes')
        toastNotification('note changes save successfully')
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error.response.data.message))
    },
    deleteNote({ dispatch }, data) {
      return api.delete(`/api/notes/${data}`).then(response => {
        // dispatch('allNotes')
        toastNotification('note deleted successfully')
        return Promise.resolve(response)
      }).catch(error => Promise.reject(error.response.data.message))
    },
  },

}
