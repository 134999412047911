// eslint-disable-next-line import/no-cycle
import api from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    comments: [],
  },
  getters: {
    getComments(state) {
      return state.comments
    },
  },
  mutations: {
    GET_COMMENTS(state, data) {
      state.comments = data
    },
  },
  actions: {
    allComments({ commit, actions }, data) {
      api.get(`/api/blog_posts/${data.post_id}/comments`)
        .then(response => {
          commit('GET_COMMENTS', response.data.data)
          return Promise.resolve(response)
        }).catch(error => Promise.reject(error.response.data.message))
    },
    postComments({ }, data) {
      api.post(`/api/blog_posts/${data.post_id}/comments`, data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error.response.data.message))
    },
    replyComment({ }, data) {
      api.post(`/api/blog_posts/${data.post_id}/comments`, data).then(response => Promise.resolve(response)).catch(error => Promise.reject(error.response.data.message))
    },
  },
}
